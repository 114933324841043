var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CustomFormRecordFormSubmit" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              "label-position": _vm.formConfig.labelPosition,
              "label-width": _vm.formConfig.labelWidth + "px",
              form: _vm.formData,
              footerShow: _vm.formShow,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
              hasHeader: _vm.showBack,
            },
            on: { update: _vm.update },
            scopedSlots: _vm._u([
              {
                key: "headerSlot",
                fn: function () {
                  return [
                    _vm.showBack
                      ? _c("v-button", {
                          attrs: { text: "返回" },
                          on: { click: _vm.returnClick },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _vm.formShow
            ? _c(
                "col2-detail",
                [
                  _c(
                    "col2-block",
                    _vm._l(_vm.formList, function (item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          item.type === "area"
                            ? _c("custom-form-item", {
                                ref: "selectedChangeValue",
                                refInFor: true,
                                attrs: {
                                  models: _vm.formData,
                                  item: item,
                                  formName: _vm.formName,
                                  formConfig: _vm.formConfig,
                                },
                                on: {
                                  "update:models": function ($event) {
                                    _vm.formData = $event
                                  },
                                },
                              })
                            : _c("custom-form-item", {
                                attrs: {
                                  models: _vm.formData,
                                  item: item,
                                  formName: _vm.formName,
                                  formConfig: _vm.formConfig,
                                },
                                on: {
                                  "update:models": function ($event) {
                                    _vm.formData = $event
                                  },
                                },
                              }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }