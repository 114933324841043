<template>
  <div class="CustomFormRecordFormSubmit">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :label-position="formConfig.labelPosition"
      :label-width="formConfig.labelWidth + 'px'"
      :form="formData"
      :footerShow="formShow"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
      :hasHeader="showBack"
    >
      <template #headerSlot>
        <v-button v-if="showBack" text="返回"  @click="returnClick" />
      </template>
      <col2-detail v-if="formShow">
        <col2-block>
          <div v-for="(item, index) in formList" :key="index">
            <custom-form-item v-if="item.type === 'area'"
              :models.sync="formData"
              :item="item"
              :formName="formName"
              :formConfig="formConfig"
              ref="selectedChangeValue"
            />
            <custom-form-item v-else
              :models.sync="formData"
              :item="item"
              :formName="formName"
              :formConfig="formConfig"
            />
          </div>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { queryDesignContentURL, updateFormDataURL, queryRecodeDetailURL, queryDetailURL } from './api'
import { Col2Detail, Col2Block } from 'components/bussiness'
import CustomFormItem from './CustomFormItem'
import moment from 'moment'

export default {
  name: 'CustomFormRecordFormSubmit',
  components: {
    Col2Detail,
    Col2Block,
    CustomFormItem
  },
  data () {
    return {
      formShow: false,
      isChange: false,
      id: undefined,
      formId: undefined,
      modelMap: {},
      formData: {},
      defaultForm: {},
      formConfig: {},
      formList: [],
      controlList: [],
      submitConfig: {
        submitUrl: updateFormDataURL,
        submitMethod: 'POST',
        queryUrl: queryRecodeDetailURL
      },
      formName: ''
    }
  },
  watch: {
    '$route': {
      async handler (newValue, oldValue) {
        this.initData()
        this.handleData()
      }
    }
  },
  computed: {
    showBack () {
      const { noBack } = this.$route.query
      return noBack === undefined
    }
  },
  mounted () {
    this.handleData()
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'customFormFormSubmit') {
      // 进入子表单编辑页时，保存下已经编辑过的信息
      this.$store.state.customTableStore.tableData = this.formData
    } else {
      // 页面离开时候，清空状态管理数据
      this.$store.state.customTableStore.tableData = {}
    }
    next()
  },
  methods: {
    initData () {
      Object.assign(this.$data, this.$options.data())
    },
    async handleData () {
      const { id, formId, formName } = this.$route.query
      this.id = id
      this.formId = formId
      // 处理面包屑显示
      if (formName && formName.length > 0) {
        this.formName = formName
      } else {
        this.queryDetail(id !== undefined)
      }
      if (id !== undefined) {
        if (this.formName && this.formName.length) {
          this.resetBreadcrumb(true)
        }
        this.$refs.formPanel.getData({
          formId,
          id
        })
      } else {
        if (this.formName && this.formName.length) {
          this.resetBreadcrumb(false)
        }
        await this.queryDesignContent()
        this.watchFormData()
        this.formShow = true
      }
    },
    initFormData (list) {
      if (Array.isArray(list) && list.length > 0) {
        list.forEach(item => {
          if (item.type === 'grid') {
            item.columns.forEach(column => {
              this.initFormData(column.list)
            })
          } else {
            if (['radio', 'checkbox'].includes(item.type)) {
              const options = item.options.options
              if (Array.isArray(options) && options.length > 0) {
                const modelMap = {}
                options.forEach(option => {
                  if (option['label'] === undefined) { // 兼容历史数据：option只有value属性
                    option['label'] = option.value
                  }
                  modelMap[option.value] = option['label']
                })
                this.modelMap[item.model] = modelMap
              }
            } else if (['select'].includes(item.type)) {
              const options = item.options.options
              if (Array.isArray(options) && options.length > 0) {
                const modelMap = {}
                options.forEach(option => {
                  if (option['label'] === undefined) { // 兼容历史数据：option只有value属性
                    option['text'] = option.value
                  } else {
                    option['text'] = option['label']
                  }
                  modelMap[option.value] = option['text']
                })
                this.modelMap[item.model] = modelMap
              }
              if (item.options.defaultValue === '') {
                item.options.defaultValue = undefined
              }
            } else if (['area'].includes(item.type)) {
              item.options.defaultValue = []
            } else if (['imgupload'].includes(item.type)) {
              if (item.options.length === 1) {
                item.options.defaultValue = ''
              }
            } else if (['usersex'].includes(item.type)) {
              const options = item.options.options
              if (Array.isArray(options) && options.length > 0) {
                const modelMap = {}
                options.forEach(option => {
                  modelMap[option.value] = option.label
                })
                this.modelMap[item.model] = modelMap
              }
            } else if (item.type === 'time') {
              item.options.defaultValue = moment().format('HH:mm')
            } else if (item.type === 'date') {
              const formatType = item.options.type
              if (formatType === 'date') {
                item.options.defaultValue = moment().format('YYYY-MM-DD')
              } else if (formatType === 'datetime') {
                item.options.defaultValue = moment().format('YYYY-MM-DD HH:mm')
              }
            }
            // 如果状态管理数据有数据，则赋值，
            if (this.$store.state.customTableStore.tableData[item.model] !== undefined) {
              item.options.defaultValue = this.$store.state.customTableStore.tableData[item.model]
            }
            if (item.options.dataBind) {
              this.formData[item.model] = item.options.defaultValue
              this.defaultForm[item.model] = item.options.defaultValue
              this.controlList.push(item)
            }
          }
        })
      }
    },
    async queryDesignContent () {
      const id = this.formId
      const params = {
        id
      }
      const { status, data } = await this.$axios.get(queryDesignContentURL, { params })
      if (status === 100) {
        try {
          const formJsonData = JSON.parse(data)
          const { config, list } = formJsonData
          this.formConfig = config
          this.formList = list
          this.initFormData(list)
        } catch {}
      }
    },
    watchFormData () {
      const _this = this
      const unwatch = _this.$watch('formData', (newValue) => {
        _this.isChange = true
        unwatch()
      })
    },
    async returnClick () {
      const isChange = this.isChange
      if (isChange) {
        const result = await this.$confirm('未保存填写是否返回')
        if (!result) {
          return
        }
      }
      this.$store.state.customTableStore.tableData = {}
      this.$router.go(-1)
    },
    submitBefore (data) {
      const formDataMap = {}
      Object.keys(data).forEach(key => {
        const formListItem = this.controlList.find((item) => {
          return item.model === key
        })
        if (formListItem) {
          const listItemType = formListItem.type
          if (['checkbox'].includes(listItemType)) {
            const checkboxDisplay = []
            if (data[key] && data[key].length > 0) {
              data[key].forEach(item => {
                checkboxDisplay.push(this.modelMap[key][item])
              })
            }
            formDataMap[key + '_display'] = checkboxDisplay.join(',')
            formDataMap[key] = data[key].join(',')
          } else if (['radio', 'select', 'usersex'].includes(listItemType)) {
            formDataMap[key + '_display'] = this.modelMap[key][data[key]]
            formDataMap[key] = data[key]
          } else if (['area'].includes(listItemType)) {
            let chil = this.$refs['selectedChangeValue']
            if (Array.isArray(chil) && chil.length > 0) {
              chil.forEach(item => {
                if (item.item.model == key) {
                  formDataMap[key + '_display'] = item.selectedChangeValue
                }
              })
            }
            formDataMap[key] = data[key] 
          } else if (['switch'].includes(listItemType)) {
            formDataMap[key + '_display'] = data[key] ? '是' : '否'
            formDataMap[key] = data[key] ? 1 : 0
          } else if (['imgupload'].includes(listItemType)) {
            if (typeof data[key] === 'string') {
              data[key] = [data[key]]
            }
            formDataMap[key] = data[key]
          } else if (['fileupload'].includes(listItemType)) {
            const fileUrlList = []
            const fileObjList = data[key]
            if (fileObjList && fileObjList.length) {
              fileObjList.forEach(fileObjItem => {
                fileUrlList.push(fileObjItem.url)
              })
            }
            formDataMap[key] = fileUrlList
          } else if (['table'].includes(listItemType)) {
            formDataMap[key] = data[key]
          } else {
            formDataMap[key] = data[key]
          }
        }
      })
      const submitData = {
        formId: this.formId,
        formDataMap
      }
      if (this.id !== undefined) {
        submitData['id'] = this.id
      }
      return submitData
    },
    getFileName (src) {
      return src.substr(src.lastIndexOf('\/') + 1)
    },
    isImage (src) {
      const imgType = ['gif', 'jpeg', 'jpg', 'bmp', 'png']
      if (RegExp('\.(' + imgType.join('|') + ')$', 'i').test(src)) {
        return true
      }
      return false
    },
    async update (data) {
      await this.queryDesignContent()
      // 如果状态管理里有值，则赋值
      if (Object.keys(this.$store.state.customTableStore.tableData).length > 0) {
        data = this.$store.state.customTableStore.tableData
      }
      Object.keys(this.formData).forEach(key => {
        const formListItem = this.controlList.find((item) => {
          return item.model === key
        })
        if (formListItem) {
          const listItemType = formListItem.type
          if (data[key] !== undefined) {
            if (['checkbox'].includes(listItemType)) {
              const value = data[key] === '' ? undefined : data[key]
              if (typeof value === 'string') {
                const result = value.split(',')
                this.formData[key] = result
              } else {
                this.formData[key] = value
              }
            } else if (['usersex'].includes(listItemType)) {
              const value = String(data[key])
              this.formData[key] = value
            } else if (['select'].includes(listItemType)) {
              const value = data[key] === '' ? undefined : data[key]
              this.formData[key] = value
            } else if (['area'].includes(listItemType)) {
              // 省市区的特殊处理
              const value = data[key] === '' ? undefined : data[key]
              if (typeof value === 'string') {
                const result = JSON.parse(value)
                this.formData[key] = result
              } else {
                this.formData[key] = value
              }
            } else if (['table'].includes(listItemType)) {
              // 子表控件特殊处理
              const value = data[key] === '' ? undefined : data[key]
              if (typeof value === 'string') {
                const result = JSON.parse(value)
                this.formData[key] = result
              } else {
                this.formData[key] = value
              }
            } else if (['switch'].includes(listItemType)) {
              const value = data[key] === 1
              this.formData[key] = value
            } else if (['imgupload', 'fileupload'].includes(listItemType)) {
              let value = []
              try {
                value = JSON.parse(data[key])
              } catch {
                value = data[key]
              }
              if (['fileupload'].includes(listItemType)) {
                if (Array.isArray(value) && value.length) {
                  const tempValue = []
                  value.forEach(url => {
                    tempValue.push({
                      name: this.getFileName(url),
                      isOther: !this.isImage(url),
                      url
                    })
                  })
                  value = tempValue
                }
              }
              if (typeof this.formData[key] === 'string') {
                this.formData[key] = value && value.length > 0 ? value[0] : ''
              } else {
                this.formData[key] = value
              }
            } else {
              this.formData[key] = data[key]
            }
          }
        }
      })
      this.watchFormData()
      this.formShow = true
    },
    async queryDetail (edit) {
      const params = {
        id: this.formId
      }
      const { status, data } = await this.$axios.get(queryDetailURL, { params })
      if (status === 100) {
        if (data) {
          const { name } = data
          this.formName = name
          this.resetBreadcrumb(edit)
        }
      }
    },
    resetBreadcrumb (edit) {
      const breadcrumb = edit ? `表单记录 —《${this.formName}》 > 编辑记录` : `表单记录 —《${this.formName}》 > 新增记录`
      this.$setBreadcrumb(breadcrumb)
    },
    submitSuccess (data, msg) {
      const { noBack } = this.$route.query
      this.$message.success('保存成功')
      if (noBack) {
        this.formData = this.defaultForm
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.CustomFormRecordFormSubmit {
  .CustomFormItem {
    ::v-deep {
      .custom-form-item-container {
        display: inline-block;
        width: 100%;
        .v-button-container {
          margin-right: 8px;
        }
      }
      .custom-width {
        .v-input-container {
          width: 100%;
          .el-input {
            width: 100% !important;
          }
        }
        .v-textarea-container {
          width: 100%;
          .el-textarea {
            width: 100% !important;
          }
        }
        .v-input-number-container {
          width: 100%;
          .el-input-number {
            width: 100% !important;
          }
        }
        .v-timepicker-contianer {
          width: 100%;
          .mx-datepicker {
            width: 100% !important;
          }
        }
        .v-datepicker-container {
          width: 100%;
          .mx-datepicker {
            width: 100% !important;
          }
        }
        .v-select-container {
          width: 100%;
          .el-select {
            width: 100% !important;
          }
        }
        .v-select2-container {
          .select2Parent {
            width: 100% !important;
          }
        }
        .v-cascader-wrapper {
          width: 100%;
          .el-cascader {
            width: 100% !important;
          }
        }
      }
    }
  }
}
</style>
